import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`PointerBox is a `}<a parentName="p" {...{
        "href": "./BorderBox"
      }}>{`BorderBox`}</a>{` component with a caret added to it.`}</p>
    <h2>{`Default example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<PointerBox m={4} p={2} minHeight={100} bg="green.1" borderColor="green.5">
  PointerBox
</PointerBox>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "live noinline",
        "live": true,
        "noinline": true
      }}>{`function PointerBoxDemo(props) {
  const [pos, setPos] = React.useState('top')

  return (
    <Box>
      <Heading as="h3" fontSize={3}>Caret Position</Heading>
      <CaretSelector current={pos} onChange={setPos} />
      <Relative pt={4}>
        <PointerBox m={4} p={2} minHeight={100} bg="green.1" borderColor="green.5" caret={pos}> Content </PointerBox>
      </Relative>
    </Box>
  )
}

function CaretSelector(props) {
  const choices = [
    'top',         'bottom',      'left',         'right',
    'left-bottom', 'left-top',    'right-bottom', 'right-top',
    'top-left',    'bottom-left', 'top-right',    'bottom-right'
  ].map((dir) => (
    <label>
      <input key={dir} type='radio' name='caret' value={dir}
        checked={dir === props.current} onChange={() => props.onChange(dir)} /> {dir}
    </label>
))

  return (
    <Grid gridTemplateColumns="repeat(4, auto)" gridGap={3} my={2}>
      {choices}
    </Grid>
  )
}

render(<PointerBoxDemo />)
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`PointerBox components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{`, `}<inlineCode parentName="p">{`LAYOUT`}</inlineCode>{`, `}<inlineCode parentName="p">{`BORDER`}</inlineCode>{`, and `}<inlineCode parentName="p">{`FLEX`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`caret`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`bottom`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the location of the caret. The format is `}<inlineCode parentName="td">{`[edge]-[position on edge]`}</inlineCode>{`. For example, `}<inlineCode parentName="td">{`right-top`}</inlineCode>{` will position the caret on the top of the right edge of the box. Use `}<inlineCode parentName="td">{`top`}</inlineCode>{`, `}<inlineCode parentName="td">{`right`}</inlineCode>{`, `}<inlineCode parentName="td">{`bottom`}</inlineCode>{`, or `}<inlineCode parentName="td">{`left`}</inlineCode>{` to position a caret in the center of that edge.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      